<template>
  <div class="menus_create-page">
    <redirect title="List menus" route="website.menus" />
    <div class="page-header">
      <h1 class="page-title">Update menu</h1>
    </div>
    <div class="px-5 py-5" v-if="isLoading">
      <SkeletonLoaders type="card-heading, list-item-three-line" />
    </div>
    <div v-else class="elevation-form px-5 py-5">
      <Notification type="error" :messages="errors" v-if="errors.length > 0" />
      <!-- Title menu -->
      <div>
        <span class=""> Title </span>
        <v-text-field
          placeholder="Title"
          v-model="menu.title"
          :error-messages="invalidSubmit && !$v.menu.title.required ? $t('Title is required') : ''"
        ></v-text-field>
      </div>
      <v-divider></v-divider>

      <!-- Button add new item -->
      <v-btn @click="onShowCreate(null)" class="mt-5" color="primary">Add menu item</v-btn>

      <!-- Menu item tree -->
      <div class="mt-5 menu-items">
        <items v-if="menu.items && menu.items.length > 0" :items="menu.items" />
        <p v-else class="text-center mt-5">This menu doesn't have any items</p>
      </div>
    </div>

    <!-- Button save menu -->
    <div class="d-flex align-center">
      <v-btn color="error" :loading="isLoadingAction" @click="del" class="mt-5">Delete</v-btn>
      <v-btn color="primary" :loading="isLoadingAction" @click="submit" class="mt-5 ml-auto">Save menu</v-btn>
    </div>

    <!-- Modal create/edit item -->
    <modal title="Menu item" :isLoading="turnOffBtn" model="showModal" :callback="saveItem">
      <template slot="content">
        <div>
          <span class=""> Name </span>
          <v-text-field
            v-model="item.name"
            :error-messages="invalid && !$v.item.name.required ? $t('Name is required') : ''"
            placeholder="e.g. About us"
          ></v-text-field>
        </div>
        <div>
          <span class=""> Link </span>
          <div class="my-3">
            <v-chip
              class="mr-2 mt-2"
              @click:close="
                removeLink();
                linkObject = null;
                showSelectCateLink = true;
              "
              close
              v-if="linkObject"
            >
              {{ linkObject.name }}
            </v-chip>
            <v-chip class="mr-2 mt-2" @click:close="removeSubLink" close v-if="selectedLink">
              {{ selectedLink.title }}
            </v-chip>
          </div>
          <v-combobox
            v-if="showSelectCateLink"
            placeholder="Search or paste a link"
            v-model="linkObject"
            @change="loadDataLink"
            :items="linkItems"
            item-text="name"
            item-value="type"
            hide-details
          ></v-combobox>
          <v-autocomplete
            v-if="!selectedLink && linkObject && linkObject.type && linkObject.type !== 'home'"
            :search-input.sync="searchLink"
            v-model="subLink"
            @change="chooseSubLink"
            :placeholder="'Search ' + linkObject.name"
            :items="dataLinks"
            :loading="isLoadingDataLink"
            item-text="title"
            hide-details
          ></v-autocomplete>
          <!-- <div v-if="formError" class="mt-3 v-messages theme--light error--text">
            {{ formError }}
          </div> -->
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { required, minLength, maxLength, email, helpers, decimal } from 'vuelidate/lib/validators';
import modal from '@/components/Modal';
import Redirect from '@/components/RedirectTo';
import Items from './components/Items';
import { menuService } from '@/apis/menu.s';
import Notification from '@/components/Notification';
import slugify from '@/helpers/slugify';
import { collectionApi } from '@/apis/collection';
import { productApi } from '@/apis/product';
import { pageService } from '@/apis/page.s';

export default {
  display: 'Nested',
  props: ['id'],
  components: {
    modal,
    Redirect,
    Items,
    Notification,
  },
  data() {
    return {
      turnOffBtn: false,
      showModal: false,
      newTree: {},
      item: {
        name: '',
        link: '',
      },
      invalid: false,
      invalidSubmit: false,
      menu: {},
      items: [],
      note: null,
      isCreated: false,
      isLoadingAction: false,
      isLoading: false,
      errors: [],
      formError: null,
      linkObject: '',
      searchLink: null,
      itemTextLink: null,
      selectedLink: null,
      isLoadingDataLink: false,
      subLink: '',
      showSubLink: false,
      showSelectCateLink: true,
      linkItems: [
        {
          name: 'Home page',
          type: 'home',
          link: '/',
        },
        {
          name: 'Collections',
          type: 'collections',
          link: '/collections/',
        },
        {
          name: 'Products',
          type: 'products',
          link: '/products/',
        },
        {
          name: 'Pages',
          type: 'pages',
          link: '/pages/',
        },
        {
          name: 'Policies',
          type: 'policies',
          link: '/policies/',
        },
      ],
      dataLinks: [],
      policies: [
        {
          title: 'Refund policy',
          handle: 'refund-policy',
        },
        {
          title: 'Privacy policy',
          handle: 'privacy-policy',
        },
        {
          title: 'Terms of services',
          handle: 'terms-of-services',
        },
        {
          title: 'Shipping policy',
          handle: 'shipping-policy',
        },
      ],
    };
  },
  validations: {
    item: {
      name: {
        required,
      },
      link: {
        required: false,
      },
    },
    menu: {
      title: {
        required,
      },
    },
  },
  created() {
    this.loadData();
  },
  watch: {
    searchLink() {
      this.loadDataLink();
    },
    'menu.items': function() {
      this.items.map(menu => {
        return Object.assign({}, menu, { deep: 1 });
      });
    },
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      try {
        let menuData = await menuService.get(this.id);
        console.log(menuData);
        this.menu = menuData.data;
        this.menu.items = this.menu.items.map(item => {
          // return { ...item, root: true };
          return Object.assign({}, item, { root: true });
        });
        this.isLoading = false;
      } catch (error) {
        this.error = error;
        this.isLoading = false;
        console.log(error);
      }
    },
    async removeLink() {
      this.item.link = '';
      this.selectedLink = null;
      this.subLink = null;
    },
    async removeSubLink() {
      this.item.link = await this.item.link.replace(this.selectedLink.handle, '');
      this.selectedLink = null;
      this.subLink = null;
      this.searchLink = '';
    },
    async chooseSubLink() {
      this.selectedLink = this.dataLinks.find(link => link.title === this.subLink);
      let link = '';
      if (this.linkObject.type === 'collections') {
        link = '/collections/' + this.selectedLink.handle;
      } else {
        link = this.linkObject.link + this.selectedLink.handle;
      }
      this.$set(this.item, 'link', link);
      this.$forceUpdate();
      this.showSelectCateLink = false;
      this.formError = null;
    },
    // khi select 1 option ở combobox thì load dữ liệu tương ứng cho complete ở dưới
    async loadDataLink() {
      console.log(this.linkObject);
      this.isLoadingDataLink = true;
      let params = {
        search: this.searchLink,
      };
      this.showSubLink = this.linkObject && this.linkObject.type && this.linkObject.type !== 'home';
      if (this.linkObject && this.linkObject.type === 'collections') {
        this.item.link = this.linkObject.link;
        this.itemTextLink = 'title';
        let documentData = await collectionApi.get(params);
        this.dataLinks = documentData.data.collections;
      } else if (this.linkObject && this.linkObject.type === 'products') {
        this.item.link = this.linkObject.link;
        console.log(params);
        this.itemTextLink = '';
        if (params) {
          console.log(params);
          let documentData = await productApi.get(params);
          this.dataLinks = documentData.data.products;
        }
      } else if (this.linkObject && this.linkObject.type === 'pages') {
        this.item.link = this.linkObject.link;
        this.itemTextLink = '';
        let documentData = await pageService.list(params);
        this.dataLinks = documentData.data.pages;
      } else if (this.linkObject && this.linkObject.type === 'policies') {
        this.item.link = this.linkObject.link;
        this.dataLinks = this.policies;
      } else if (this.linkObject && this.linkObject.type === 'home') {
        this.item.link = '/';
      } else {
        this.item.link = this.linkObject;
      }
      console.log(this.item);
      this.isLoadingDataLink = false;
      this.formError = null;
    },
    /*
      Show modal create item
      input: object
      output: void
    */
    onShowCreate(data) {
      this.note = data;
      this.turnOffBtn = false;
      this.showModal = true;
      this.invalid = false;
      this.item.name = '';
      this.item.link = '';
      this.isCreated = true;
      this.linkObject = null;
      this.selectedLink = null;
      this.showSelectCateLink = true;
      this.searchLink = '';
      this.subLink = '';
    },
    /*
      Show modal edit item
      input: object
      output: void
    */
    onShowEdit(data) {
      this.note = data;
      this.showModal = true;
      this.invalid = false;
      this.item.name = data.name;
      this.item.link = data.link;
      this.isCreated = false;
    },
    /*
      Delete item
      input: object
      output: void
    */
    onDeleteNote(data) {
      data._vm.store.deleteNode(data);
    },
    /*
      Func add child item
      input: object
      output: void
    */
    addChild(data) {
      console.log(this.item.link);
      let obj = {};
      obj.name = this.item.name;
      obj.link = this.item.link;
      obj.children = [];
      data.children.push(obj);
    },
    /*
      Func on change tree node
      input: object
      output: void
    */
    onChange(node, targetTree) {
      console.log(targetTree.getPureData());
    },
    /*
      Func save modal
      input: void
      output: void
    */
    async saveItem() {
      this.$v.$touch();
      this.invalid = this.$v.item.$invalid;
      setTimeout(() => {
        if (!this.invalid) {
          let obj = {};
          obj.id = 'item-' + new Date().getTime().toString(36);
          obj.name = this.item.name;
          obj.link = this.item.link;
          obj.handle = slugify(this.item.name);
          obj.children = [];
          if (this.note) {
            if (this.isCreated) {
              this.addChild(this.note);
            } else {
              this.note.name = this.item.name;
              this.note.handle = slugify(this.item.name);
              this.note.link = this.item.link;
            }
          } else {
            this.menu.items.push(obj);
          }
          this.showModal = false;
        }
      }, 500);
    },
    /*
      Func submit
      input: void
      output: void
    */
    submit() {
      this.$v.$touch();
      this.isLoadingAction = true;
      this.invalidSubmit = this.$v.menu.title.$invalid;
      if (!this.invalidSubmit) {
        try {
          let menuData = menuService.update(this.menu);

          this.isLoadingAction = false;
        } catch (error) {
          this.errors = error;
          this.isLoadingAction = false;
        }
      }
      this.isLoadingAction = false;
    },
    async del() {
      this.isLoadingAction = true;
      try {
        let data = await menuService.deleteById(this.id);

        this.isLoadingAction = false;
        this.$router.push({ name: 'website.menus' });
      } catch (error) {
        this.errors = error;
        this.isLoadingAction = false;
      }
    },
  },
};
</script>

<style lang="scss">
.menus_create-page {
  .vtl-node-content {
    width: 100%;
  }
  .vtl-operation {
    display: none;
  }
  .menu-items {
    min-height: 200px;
    max-height: 500px;
    background-color: var(--v-border-base);
    padding: 10px;
    overflow-y: auto;
  }
}
</style>
